import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const PlayerAnalyticsPage = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  async function getPlayerData() {
    try {
      const response = await fetch("https://citypridemal-web-app.onrender.com/players");
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error from server:", errorData.error);
        setError(errorData.error);
      } else {
        const playerData = await response.json();
        setData(playerData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data. Please check your network connection or server status.");
    }
  }

  useEffect(() => {
    getPlayerData();

    // Initialize socket inside useEffect to avoid multiple connections
    const socket = io("https://citypridemal-web-app.onrender.com");

    // Log connection events
    socket.on("connect", () => {
      console.log("Connected to server");
    });
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Listen for new user entry events
    socket.on("newUserEntry", (data) => {
      console.log("Received newUserEntry event:", data); // Log data to confirm reception
      alert(data.message); // Show an alert with the message from the server
    });

    // Cleanup the socket connection on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div>
        <h1>Player Analytics</h1>
  
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        
        {data.length === 0 && !error && <p style={{ textAlign: 'center' }}>Loading player data...</p>}
        
        {data.length > 0 && (
          <table style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Player Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Phone Number</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Location</th>
              </tr>
            </thead>
            <tbody>
              {data.map((player, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{player.name}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{player.phonenumber}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{player.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default PlayerAnalyticsPage;
