import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [name, setname] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const location = localStorage.getItem('userLocation');
  
  
  console.log(name, phonenumber, location);

  const handleSubmit = async(e) => {
            e.preventDefault();
            const addUser = {name, phonenumber, location} ;
    try{
    const user = await fetch ("https://citypridemal-web-app.onrender.com/success", {
        method:"POST",
        body: JSON.stringify(addUser),
        headers: {
            "Content-type":"application/json",
        },
    });

    if (!user.ok) {
        const errorText = await user.text(); // This gives more info on the HTML error
        console.error("Error:", errorText);
        return;
    }
    
    const result = await user.json();
    console.log(result);
    navigate("/");
  }
  catch (error) {
    console.error("Failed to fetch:", error);
  }
};
 

  return (
    <div style={styles.container}>
      <div style={styles.animation}>🎉🎉🎉</div>
      <div style={styles.header}>CONGRATULATIONS</div>
      <div style={styles.subHeader}>
        You have won the chance to win Rs 25,000! 
        <br />
        Submit your details below:
        <br />
        We will select 100 Lucky Participants to play the game at the Mall, and 1 LUCKY WINNER will be awarded with Rs 25,000 Cash Prize.
        <br />
        Also, we have some surprises planned for remaining participants.
        <br />
        SO, Try your luck today!!!
      </div>

      <div style={styles.formContainer}>
        <form  onSubmit = {handleSubmit} style={styles.form}>
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setname(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="text"
            placeholder="Your Phone Number"
            value={phonenumber}
            onChange={(e) => setphonenumber(e.target.value)}
            style={styles.input}
            required
          />
          <button type="submit" style={styles.button}>Submit</button>
        </form>
      </div>
    </div>
  );
};

// Basic Styling
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '0 5vw',
    overflow: 'auto',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  header: {
    fontSize: '4vw',
    fontWeight: 'bold',
    color: '#ff5722',
    marginBottom: '3vw',
    textAlign: 'center',
    lineHeight: 1.2,
  },
  subHeader: {
    fontSize: '2.5vw',
    color: '#333',
    marginBottom: '4vw',
    textAlign: 'center',
    lineHeight: 1.4,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
    marginTop: '3vw',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1.5vw',
  },
  input: {
    padding: '1.5vw',
    fontSize: '1.5vw',
    width: '100%',
    marginBottom: '2vw',
    borderRadius: '8px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    padding: '1.8vw',
    fontSize: '2vw',
    backgroundColor: '#ff5722',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: 'auto',
    maxWidth: '200px',
    boxSizing: 'border-box',
  },
  animation: {
    fontSize: '5vw',
    marginTop: '3vw',
    textAlign: 'center',
  }
};

export default SuccessPage;  // Exporting the component for use in other files
