import React, { useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';

const LandingPage = () => {
  const { location } = useParams();
  const hasPosted = useRef(false); // A ref to track if the location data has been posted

  // Function to post location data to the backend
  const postLocationData = async () => {
    if (!location || hasPosted.current) return;  // Ensure location is defined and data is not posted twice

    try {
      const response = await fetch(`https://citypridemal-web-app.onrender.com/location/${location}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        localStorage.setItem("userLocation", location);
        hasPosted.current = true; // Mark as posted to prevent further requests
      } else {
        console.error("Error posting location:", response.statusText);
      }
    } catch (error) {
      console.error("Error posting location:", error);
    }
  };

  // Run the postLocationData function only once on component mount
  useEffect(() => {
    postLocationData();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div style={styles.container}>
      <div style={styles.header}>CITYPRIDEMALL CONTEST</div>
      <div style={styles.subHeader}>Play the Game & Win Rs 25,000</div>
      <Link to="/game">
        <button style={styles.button}>Play Game</button>
      </Link>
    </div>
  );
};

// Basic Styling with Mobile-Responsive Adjustments using relative units
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '0 5vw',
  },
  header: {
    fontSize: '5vw',
    fontWeight: 'bold',
    color: '#ff5722',
    marginBottom: '2vw',
    textAlign: 'center',
  },
  subHeader: {
    fontSize: '2.5vw',
    color: '#333',
    marginBottom: '4vw',
    textAlign: 'center',
  },
  button: {
    padding: '2vw 4vw',
    fontSize: '2vw',
    backgroundColor: '#ff5722',
    color: '#fff',
    border: 'none',
    borderRadius: '0.8vw',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: 'auto',
    maxWidth: '40vw',
  },
  '@media (max-width: 600px)': {
    header: {
      fontSize: '8vw',
    },
    subHeader: {
      fontSize: '4.5vw',
    },
    button: {
      padding: '3vw 5vw',
      fontSize: '4vw',
    },
  },
};

export default LandingPage;
