import React, { useState } from 'react';
import cottonking from '../assets/cottonking.jpeg';
import peterengland from '../assets/peterengland.png';
import vfashion from '../assets/vfashion.png';
import yashshree from '../assets/yashshree.jpeg';
import littleWonders from '../assets/littlewonders.png';
import kidzkorner from '../assets/kidzkorner.png';
import khadim from '../assets/khadim.png';
import dipjyotFootwear from '../assets/dipjyoti.png';
import killer from '../assets/killer.png';
import iconic from '../assets/iconic.jpeg';
import funzone from '../assets/funzone.jpg';

const HomePage = () => {

    const [privacyPopupVisible, setPrivacyPopupVisible] = useState(false);
    const [termsPopupVisible, setTermsPopupVisible] = useState(false);

    // Show Privacy Policy Popup
    const handlePrivacyClick = (event) => {
        event.preventDefault();
        setPrivacyPopupVisible(true);
    };

    // Hide Privacy Policy Popup
    const closePrivacyPopup = () => {
        setPrivacyPopupVisible(false);
    };

    // Show Terms & Conditions Popup
    const handleTermsClick = (event) => {
        event.preventDefault();
        setTermsPopupVisible(true);
    };

    // Hide Terms & Conditions Popup
    const closeTermsPopup = () => {
        setTermsPopupVisible(false);
    };
    return (
        
        <div style = {styles.body}>
            <header >
                <nav style={styles.header}>
                    <ul style={styles.navul}>
                        <li style={styles.navula}><a href="#home" style={styles.link}>Home</a></li>
                        <li style={styles.navula}><a href="#services" style={styles.link}>Services</a></li>
                        <li style={styles.navula}><a href="#about" style={styles.link}>About Us</a></li>
                        <li style={styles.navula}><a href="#contact" style={styles.link}>Contact Us</a></li>
                    </ul>
                </nav>
            </header>

            <section id="home" className="hero" style ={styles.hero}>
                <h1 style = {styles.heroTitle}>CITYPRIDEMALL</h1>
                <h2 style = {styles.heroSubtitle}>The new PRIDE of LASALGAON</h2>
            </section>

            <section id="services" className="services" style = {styles.services}>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Men's Wear</h1>
                    <img style = {styles.serviceBlockImages} src={cottonking} alt="cottonking"></img>
                    <img style = {styles.serviceBlockImages} src={peterengland} alt="peterengland"></img>
                    <img style = {styles.serviceBlockImages} src={vfashion} alt="vfashion"></img>
                    <img style = {styles.serviceBlockImages} src={killer} alt="killer"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Women's Wear</h1>
                    <img style = {styles.serviceBlockImages} src={yashshree} alt="yashshree"></img>
                    <img style = {styles.serviceBlockImages} src={vfashion} alt="vfashion"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Kids' Wear</h1>
                    <img style = {styles.serviceBlockImages} src={kidzkorner} alt="kidzkorner"></img>
                    <img style = {styles.serviceBlockImages} src={littleWonders} alt="littlewonders"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Foot Wear</h1>
                    <img style = {styles.serviceBlockImages} src={khadim} alt="khadim"></img>
                    <img style = {styles.serviceBlockImages} src={dipjyotFootwear} alt="dipjyotFootwear"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Salon & Spa</h1>
                    <img style = {styles.serviceBlockImages} src={iconic} alt="iconic"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Art Jewellery</h1>
                    <img style = {styles.serviceBlockImages} src={yashshree} alt="yashshree"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Fun Zone</h1>
                    <img style = {styles.serviceBlockImages} src={funzone} alt="funzone"></img>
                </div>
                <div style = {styles.serviceBlock}>
                    <h1 style = {styles.serviceBlockTitle}>Desi Bazaar</h1>
                </div>
            </section>
            <section id="about" className="about" style = {styles.about} >
            <div className="about-content" style = {styles.aboutcontent} >
                <h2>About City Pride Mall</h2>
                <p>Welcome to City Pride Mall, the pride of Lasalgaon. Discover our story and vision for the future.</p>
            </div>
            </section>
        <footer id="contact" style = {styles.footer}>
            <p>City Pride Mall | Address: KotamgaonRoad, Lasalgaon, Nashik, Maharashtra, 422306, India</p>
            <p>Phone: +91-9975370009</p>

            <ul className="social-media" style = {styles.socialMedia}>
                <li><a style = {styles.socialMediaItem} href="https://www.facebook.com/profile.php?id=61559847741817">Facebook</a></li>
                <li><a style = {styles.socialMediaItem} href="https://instagram.com/citypridemall_/">Instagram</a></li>
                <li><a style = {styles.socialMediaItem} href="https://www.youtube.com/@CITYPRIDEMALLLASALGAON">YouTube</a></li>
            </ul>
            <div style={styles.linkContainer}>
                    <a href="#" id="privacy-link" onClick={handlePrivacyClick} style={styles.link}>Privacy Policy</a>
                    <a href="#" id="terms-link" onClick={handleTermsClick} style={styles.link}>Terms & Conditions</a>
            </div>
                        
                {privacyPopupVisible && (
                    <div className="popup" style = {styles.popupOverlay}>
                        <div className="popup-content"  style = {styles.popup}>
                                <h3>Privacy Policy</h3>
                                <p>
                                    <p><strong>Privacy Policy for CityPrideMall</strong></p>
                                    At CityPride Mall, accessible from citypridemall.com, one of our main priorities 
                                    is the privacy of our visitors. 
                                    This Privacy Policy document contains types of information that are collected and 
                                    recorded by CityPride Mall and how we use it. 
                                    If you have additional questions or require more information about our Privacy Policy, 
                                    do not hesitate to contact us.
                                    
                                    <p><strong>Consent</strong></p>
                                    
                                    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                                    
                                    <p><strong> Information we collect </strong></p>
                                    
                                    The personal information that you are asked to provide, 
                                    and the reasons why you are asked to provide it, 
                                    will be made clear to you at the point we ask you to provide your personal information. 
                                    If you contact us directly, we may receive additional information about you 
                                    such as your name, email address, phone number, the contents of the message 
                                    and/or attachments you may send us, and any other information you may choose to provide. 
                                    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                                    
                                    <p><strong> How we use your information </strong></p>
                                    
                                    We use the information we collect in various ways, including to:
                                    
                                    Provide, operate, and maintain our website
                                    Improve, personalize, and expand our website
                                    Understand and analyze how you use our website
                                    Develop new products, services, features, and functionality
                                    Communicate with you, either directly or through one of our partners, 
                                    including for customer service, to provide you with 
                                    updates and other information relating to the website, and 
                                    for marketing and promotional purposes
                                    Send you emails
                                    Find and prevent fraud
                                    Log Files
                                    
                                    Citypridemall follows a standard procedure of using log files. 
                                    These files log visitors when they visit websites. 
                                    All hosting companies do this and a part of hosting services' analytics. 
                                    The information collected by log files include internet protocol (IP) addresses, 
                                    browser type, Internet Service Provider (ISP), date and time stamp, 
                                    referring/exit pages, and possibly the number of clicks. 
                                    These are not linked to any information that is personally identifiable. 
                                    The purpose of the information is for analyzing trends, administering the site, 
                                    tracking users' movement on the website, and gathering demographic information.
                                    
                                    <p><strong> Cookies and Web Beacons </strong></p>
                                    
                                
                                    Like any other website, CityPride Mall uses 'cookies'. 
                                    These cookies are used to store information including visitors' preferences, 
                                    and the pages on the website that the visitor accessed or visited.
                                    The information is used to optimize the users' experience by customizing 
                                    our web page content based on visitors' browser type and/or other information.
                                    
                                    <p><strong> Advertising Partners Privacy Policies </strong></p>
                                    
                                    You may consult this list to find the Privacy Policy for each of the 
                                    advertising partners of cityprideMall. 
                                    Third-party ad servers or ad networks uses technologies like cookies, 
                                    JavaScript, or Web Beacons that are used in their respective advertisements 
                                    and links that appear on citypridemall, which are sent directly to users' browser. 
                                    They automatically receive your IP address when this occurs. 
                                    These technologies are used to measure the effectiveness of their advertising campaigns 
                                    and/or to personalize the advertising content that you see on websites that you visit. 
                                    Note that Citypridemall has no access to or control over these cookies that are used 
                                    by third-party advertisers.
                                    
                                    <p><strong>Third Party Privacy Policies</strong></p>
                                    
                                    CityPrideMall's Privacy Policy does not apply to other advertisers or websites. 
                                    Thus, we are advising you to consult the respective Privacy Policies of these 
                                    third-party ad servers for more detailed information. 
                                    It may include their practices and instructions about how to opt-out of certain options. 
                                    You can choose to disable cookies through your individual browser options. 
                                    To know more detailed information about cookie management with specific web browsers, 
                                    it can be found at the browsers' respective websites.
                                    
                                    <p><strong>Do Not Sell My Personal Information</strong></p>
                                    Among other rights, consumers have the right to: 
                                    Request that a business that collects a consumer's personal data disclose 
                                    the categories and specific pieces of personal data that a business has collected 
                                    about consumers. Request that a business delete any personal data about the consumer
                                    that a business has collected. Request that a business that sells 
                                    a consumer's personal data, not sell the consumer's personal data. 
                                    If you make a request, we have one month to respond to you. 
                                    If you would like to exercise any of these rights, please contact us.
                                    
                                    <p><strong>GDPR Data Protection Rights</strong></p>
                                    We would like to make sure you are fully aware of all of your data protection rights. 
                                    Every user is entitled to the following: 
                                    The right to access – 
                                    You have the right to request copies of your personal data. 
                                    We may charge you a small fee for this service. 
                                    The right to rectification – 
                                    You have the right to request that we correct any information you believe is inaccurate. 
                                    You also have the right to request that we complete the information 
                                    you believe is incomplete. 
                                    The right to erasure – 
                                    You have the right to request that we erase your personal data, 
                                    under certain conditions. 
                                    The right to restrict processing – 
                                    You have the right to request that we restrict the processing of your personal data, 
                                    under certain conditions. 
                                    The right to object to processing – 
                                    You have the right to object to our processing of your personal data, 
                                    under certain conditions. 
                                    The right to data portability – 
                                    You have the right to request that we transfer the data 
                                    that we have collected to another organization, or directly to you, 
                                    under certain conditions. 
                                    If you make a request, we have one month to respond to you. 
                                    If you would like to exercise any of these rights, please contact us.
                                    Children's Information
                                    Another part of our priority is adding protection for children 
                                    while using the internet. We encourage parents and guardians to observe, 
                                    participate in, and/or monitor and guide their online activity. 
                                    Citypridemall does not knowingly collect any Personal Identifiable Information 
                                    from children under the age of 13. 
                                    If you think that your child provided this kind of information on our website, 
                                    we strongly encourage you to contact us immediately and 
                                    we will do our best efforts to promptly remove such information from our records.
                                </p>
                                <button onClick={closePrivacyPopup} style = {styles.closeButton}>Close</button>
                            </div>
                        </div>
                     )}
                       

                    {termsPopupVisible && (
                    <div className="popup" style = {styles.popupOverlay}>
                        <div className="popup-content" style = {styles.popup}>
                        <h3>Terms & Conditions</h3>
                        <p><strong>These Terms and Conditions govern your use of Citypridemall (the "Website")</strong></p>
                        <p>By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access the Website.</p>

                        <h4><strong>1. Use of Website</strong></h4>
                        <p>You agree to use the Website only for lawful purposes and in accordance with these Terms. You must not:</p>
                        <ul>
                            <li>Violate any applicable laws or regulations.</li>
                            <li>Interfere with the operation of the Website or disrupt its functionality.</li>
                            <li>Use the Website in any way that could damage, disable, overburden, or impair the Website or interfere with any other party's use of the Website.</li>
                        </ul>

                        <h4><strong>2. Products</strong></h4>
                        <p>All products displayed on the Website are for sale. However, purchasing transactions are not available on the Website itself.</p>
                        <p>To inquire about purchasing a product, users may contact us through the social media icons & initiate a query.</p>

                        <h4><strong>3. Intellectual Property</strong></h4>
                        <p>The Website and its original content, features, and functionality are owned by citypridemall and protected by intellectual property laws.</p>

                        <h4><strong>4. Disclaimer</strong></h4>
                        <p>The Website is provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, and hereby disclaim all other warranties.</p>

                        <h4><strong>5. Limitation of Liability</strong></h4>
                        <p>In no event shall citypridemall, nor its affiliates, be liable for any damages resulting from:</p>
                        <ul>
                            <li>Your access to or use of or inability to access the Website.</li>
                            <li>Any content obtained from the Website.</li>
                        </ul>

                        <h4><strong>6. Governing Law</strong></h4>
                        <p>These Terms shall be governed and construed in accordance with the laws of India.</p>

                        <h4><strong>7. Changes</strong></h4>
                        <p>We reserve the right to modify or replace these Terms at any time.</p>

                        <h4><strong>Contact Us</strong></h4>
                        <p>If you have any questions, contact us at +91 9975370009 or email us at citypridemall@gmail.com.</p>


                        <button onClick={closeTermsPopup} style = {styles.closeButton}>Close</button>

                    </div>
                </div>
            )}

                
        </footer>
            
            
        </div>
    );

};

const styles = {
    body: {
        margin: 0,
        padding: 0,
        fontFamily: "'Georgia', serif",
        color: '#333',
        scrollBehavior: 'smooth',
        overflowX: 'hidden',
        width: '100%',
        boxSizing: 'border-box',
    },
    header: {
        background: '#333',
        color: 'white',
        position: 'fixed',
        width: '100%',
        top: '0',
        zIndex: 1000,
        padding: '10px 0', // Reduced padding for compactness
        display: 'flex',
        justifyContent: 'center',
    },
    navul: {
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '10px', // Space between items for readability
    },
    navula: {
        textDecoration: 'none',
        color: 'white',
        padding: '10px 15px',
        display: 'block',
        fontSize: '1em',
    },
    hero: {
        backgroundColor: '#222',
        color: 'white',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        padding: '0 5vw',
        boxSizing: 'border-box',
    },
    heroTitle: {
        fontSize: '6vw',
        maxWidth: '90%',
        margin: '0 auto',
        lineHeight: '1.2',
        textAlign: 'center',
        whiteSpace: 'nowrap', // Prevents line breaks
    },
    heroSubtitle: {
        fontSize: '4vw',
        maxWidth: '80%',
        margin: '0 auto',
        textAlign: 'center',
        whiteSpace: 'nowrap', // Prevents line breaks
    },
    services: {
        backgroundColor: '#111',
        color: 'white',
        padding: '40px 20px',
    },
    serviceBlock: {
        padding: '40px 10px',
        textAlign: 'center',
        backgroundColor: '#000',
        marginBottom: '20px',
        borderRadius: '8px',
    },
    serviceBlockTitle: {
        fontSize: '2em',
        marginBottom: '10px',
    },
    serviceBlockImages: {
        width: '80px', // Smaller images for responsiveness
        height: 'auto',
        margin: '10px',
    },
    about: {
        backgroundColor: '#333',
        color: 'white',
        padding: '60px 20px',
        textAlign: 'center',
    },
    aboutContent: {
        maxWidth: '600px',
        margin: 'auto',
    },
    footer: {
        background: '#333',
        color: 'white',
        padding: '40px 20px',
        textAlign: 'center',
    },
    socialMedia: {
        listStyleType: 'none',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
    },
    socialMediaItem: {
        color: 'white',
        fontSize: '1.2em', // Adjusted font size for icons
    },
    popupOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popup: {
        backgroundColor: '#fff',
        color: '#333',
        width: '90%',
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    },
    closeButton: {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '1em',
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '10px',
        marginTop: '10px',
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        fontSize: '1em',
        cursor: 'pointer',
    },

     // Responsive Styles
     '@media (max-width: 768px)': {
        heroTitle: {
            fontSize: '7vw',
        },
        heroSubtitle: {
            fontSize: '5vw',
        },
        navula: {
            fontSize: '3vw', // Increased font size for nav
        },
        serviceBlockTitle: {
            fontSize: '4vw',
        },
        serviceBlockContent: {
            fontSize: '3vw',
        },
        serviceBlockImages: {
            width: '15vw',
        },
    },

    '@media (max-width: 480px)': {
        heroTitle: {
            fontSize: '8vw',
        },
        heroSubtitle: {
            fontSize: '6vw',
        },
        navula: {
            fontSize: '3.5vw',
        },
        serviceBlockTitle: {
            fontSize: '5vw',
        },
        serviceBlockContent: {
            fontSize: '3.5vw',
        },
        serviceBlockImages: {
            width: '20vw',
        },
    },
};
export default HomePage;
