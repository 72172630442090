import React from 'react';
import { Link } from 'react-router-dom';

const FailurePage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>BETTER LUCK NEXT TIME</div>

      {/* Failure emoji animation */}
      <div style={styles.animation}>😞</div>

      <div style={styles.buttonsContainer}>
        {/* Button to play again */}
        <Link to="/game">
          <button style={styles.button}>Play Again</button>
        </Link>

        {/* Button to go to home */}
        <Link to="/">
          <button style={styles.button}>Home</button>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh', // Changed height to minHeight to allow for content overflow
    backgroundColor: '#f4f4f4',
    padding: '0 5vw',
    overflow: 'auto', // Allows scrolling if content overflows
  },
  header: {
    fontSize: '5vw',
    fontWeight: 'bold',
    color: '#ff5722',
    marginBottom: '3vw', // Adjusted margin to avoid cut-off
    textAlign: 'center',
  },
  animation: {
    fontSize: '10vw',
    marginTop: '2vw',
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '4vw',
  },
  button: {
    padding: '2vw 4vw',
    fontSize: '2vw',
    backgroundColor: '#ff5722',
    color: '#fff',
    border: 'none',
    borderRadius: '0.8vw',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: 'auto',
    maxWidth: '40vw',
  },
  '@media (max-width: 600px)': {
    header: {
      fontSize: '8vw',
    },
    animation: {
      fontSize: '15vw',
    },
    button: {
      fontSize: '4vw',
      padding: '6vw 8vw',
    },
  },
};

export default FailurePage;
