import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GamePage = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();  // Hook to navigate programmatically

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    // Conditional check based on the selected option
    if (selectedOption === "A") {
      navigate("/success");  // Redirect to the success page if answer is correct
    } else {
      navigate("/failure");  // Redirect to the failure page if answer is incorrect
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>Puzzle Question</div>
      <div style={styles.riddle}>What has keys but can't open locks?</div>

      <div style={styles.optionsContainer}>
        <label style={styles.option}>
          <input
            type="radio"
            name="option"
            value="A"
            onChange={handleOptionChange}
            style={styles.radioButton}
          />
          A. A Piano
        </label>
        <label style={styles.option}>
          <input
            type="radio"
            name="option"
            value="B"
            onChange={handleOptionChange}
            style={styles.radioButton}
          />
          B. A Car
        </label>
        <label style={styles.option}>
          <input
            type="radio"
            name="option"
            value="C"
            onChange={handleOptionChange}
            style={styles.radioButton}
          />
          C. A Computer
        </label>
        <label style={styles.option}>
          <input
            type="radio"
            name="option"
            value="D"
            onChange={handleOptionChange}
            style={styles.radioButton}
          />
          D. A Lock
        </label>
      </div>

      <button style={styles.button} onClick={handleSubmit}>
        Submit Answer
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '0 5vw',
  },
  header: {
    fontSize: '5vw',
    fontWeight: 'bold',
    color: '#ff5722',
    marginBottom: '2vw',
    textAlign: 'center',
  },
  riddle: {
    fontSize: '3vw',
    color: '#333',
    marginBottom: '3vw',
    textAlign: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '4vw',
  },
  option: {
    fontSize: '2vw',
    margin: '1vw 0',
    cursor: 'pointer',
  },
  radioButton: {
    marginRight: '1vw',
  },
  button: {
    padding: '2vw 4vw',
    fontSize: '2vw',
    backgroundColor: '#ff5722',
    color: '#fff',
    border: 'none',
    borderRadius: '0.8vw',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: 'auto',
    maxWidth: '40vw',
  },
  '@media (max-width: 600px)': {
    header: {
      fontSize: '8vw',
    },
    riddle: {
      fontSize: '5vw',
    },
    option: {
      fontSize: '4vw',
    },
    button: {
      padding: '3vw 5vw',
      fontSize: '4vw',
    },
  },
};

export default GamePage;
