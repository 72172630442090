import './App.css';
import LandingPage from './components/landingpage';
import GamePage from './components/gamepage';
import FailurePage from './components/failurepage';
import SuccessPage from './components/successpage';
import HomePage from './components/homepage';
import LocationAnalyticsPage from './components/marketinganalyticspage';
import PlayerAnalyticsPage from './components/playeranalyticspage';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path = '/location/:location' element = {<LandingPage/>} />
        <Route exact path = '/game' element = {<GamePage/>} />
        <Route exact path = '/success' element = {<SuccessPage/>} />
        <Route exact path = '/failure' element = {<FailurePage/>} />
        <Route exact path = '/' element = {<HomePage/>} />
        <Route exact path = '/marketinganalytics' element = {<LocationAnalyticsPage/>} />
        <Route exact path = '/playeranalytics' element = {<PlayerAnalyticsPage/>} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
