import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const socket = io("https://citypridemal-web-app.onrender.com"); // Connect to backend server

const MarketingAnalyticsPage = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  async function getMarketingData() {
    try {
      const response = await fetch("https://citypridemal-web-app.onrender.com/location-counts");
      const marketingData = await response.json();
      
      if (!response.ok) {
        console.log(marketingData.error);
        setError(marketingData.error); 
      } else {
        setData(marketingData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data.");
    }
  }

  useEffect(() => {
    getMarketingData();

    // Listen for new entries from the server
    socket.on("newEntry", (data) => {
      alert(data.message);
      getMarketingData(); // Refresh data to include the new entry
    });

    // Cleanup on component unmount
    return () => socket.off("newEntry");
  }, []);
  console.log(data);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div>
        <h1>Marketing Analytics</h1>
        
        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        <table className="table" style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Location</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Count</th>
            </tr>
          </thead>
          <tbody>
            {data.map((ele, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px', wordWrap: 'break-word' }}>{ele._id}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{ele.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MarketingAnalyticsPage;

